<template>
  <ul class="logo_strip_img row">
    <template v-for="(brand, i) in brands">
      <li class="col-md-2 mb-3" :key="brand.slug + i">
        <router-link to="#." tag="a" >
          <img :src="publicPath + brand.img" width="106" height="60" />
        </router-link>
      </li>
    </template>
  </ul>
</template>
<script>
export default {
  name: "brandsCopm",
  data() {
    return {
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/",
      brands: []
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$urlmain
        .get("brands-popular.json")
        .then(response => {
          this.brands = response.data.data;
          response => (this.brands = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
