<template>
<div  class="container providers" >
  <div class="row">
    <div class="col-12">
      <h3 class="Networks_Brands mt-5 mt-md-0">Hot Products</h3>
    </div>
  </div>
  <ul class="row mt-5 mb-4 justify-content-start">
    <template v-for="(category) in homeOffers">
      <template  v-for="category1 in category.products">
        <li  v-for="(category2, i) in category1.offers" :key="i" class="list-group-item col-md-4 text-left border-0">
          <router-link
            :to="
              '/categories/' +
                category.slug +
                '/' +
                category1.brand.slug +
                '/' +
                category1.slug +
                '?product=' +
                category1.id +
                '&network=' +
                category2.networks_id
            "
            tag="a"
            class="border-bottom border-top-0 border-left-0 border-right-0 d-block w-100 h-100 px-2 py-2"
            target="_blank"
            rel="nofollow"
            >{{ category2.title }}</router-link>
        </li>
      </template>
    </template>
  </ul>
</div>
</template>

<script>
export default {
  name: "homeOffersCom",
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        direction: "horizontal",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 5
          }
        }
      },
      homeOffers: [],
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/"
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$urlmain
        .get("category-offers.json")
        .then(response => {
          this.homeOffers = response.data.data;
          response => (this.homeOffers = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
