<template>
  <!-- brand sale area start -->
  <div class="brand-area pb-70 mt-3">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h3><span>Latest</span> News</h3>
          </div>
        </div>
        <div class="col-12">
          <ul  class="row mb-4">
              <li  v-for="(blogs, i) in blogs" :key="i" class="list-group-item col-md-4 text-left border-0">
                  <router-link :to="'/guides/' + blogs.id"
                    class="border-bottom border-top-0 border-left-0 border-right-0 d-block w-100 h-100 px-4 py-2"
                  >{{ blogs.title }}</router-link>
              </li>
          </ul>
          <!-- <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="(blogs, i) in blogs" :key="i">
              <div class="single-blogg-item mb-30">
                <div class="blogg-thumb">
                  <router-link
                    :to="'/guides/' + blogs.blogcategories.slug + '/' + blogs.id"
                    tag="a"
                  >
                    <img :src="'https://app.contractsexpert.co.uk/storage/app/public/' + logs.img  " alt class="d-block m-auto" />
                  </router-link>
                </div>
                <div class="blogg-content">
                  <span class="post-date">{{
                    blogs.created_at | moment("ddd, DD-MM-YYYY")
                  }}</span>
                  <h5>
                    <router-link
                      :to="
                        '/guides/' + blogs.blogcategories.slug + '/' + blogs.id
                      "
                      >{{ blogs.title }}</router-link
                    >
                  </h5>
                  <div v-html="getPragraphLimit(blogs.desc, 250)"></div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="m-auto text-center">
              <div class="show_all_item">
                <router-link
                  class="btn-cart show_all"
                  :to="'/news?all_blogs'"
                  tag="a"
                  >Show all</router-link
                >
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper> -->
        </div>
      </div>
    </div>
  </div>
  <!-- brand sale area end -->
</template>
<script>
export default {
  name: "newsCopm",
  data() {
    return {
      swiperOption: {
        // slidesPerView: 3,
        spaceBetween: 30,
        direction: "horizontal",
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      },
      blogs: []
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$urlmain
        .get("blog-popular.json")
        .then(response => {
          this.blogs = response.data.data;

          response => (this.blogs = response.data.data);
          console.log(this.blogs[0].desc.length);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getPragraphLimit(pragraph) {
      if (pragraph.length > 0) {
        return pragraph.substring(0, 300) + "...";
      } else {
        return pragraph;
      }
    }
  }
};
</script>
