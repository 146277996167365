var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container providers"},[_vm._m(0),_c('ul',{staticClass:"row mt-5 mb-4 justify-content-start"},[_vm._l((_vm.homeOffers),function(category){return [_vm._l((category.products),function(category1){return _vm._l((category1.offers),function(category2,i){return _c('li',{key:i,staticClass:"list-group-item col-md-4 text-left border-0"},[_c('router-link',{staticClass:"border-bottom border-top-0 border-left-0 border-right-0 d-block w-100 h-100 px-2 py-2",attrs:{"to":'/categories/' +
                category.slug +
                '/' +
                category1.brand.slug +
                '/' +
                category1.slug +
                '?product=' +
                category1.id +
                '&network=' +
                category2.networks_id,"tag":"a","target":"_blank","rel":"nofollow"}},[_vm._v(_vm._s(category2.title))])],1)})})]})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"Networks_Brands mt-5 mt-md-0"},[_vm._v("Hot Products")])])])}]

export { render, staticRenderFns }