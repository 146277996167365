<template>
  <div id="home-page">
    <PageLoader />
    <div class="row m-0">
      <div class="Looking_for_mobile col-12">
        <div class="container">
          <!-- <h1>Get a better deal on your broadband</h1>
          <p>You could save hundreds of pounds per year when you compare and switch broadband providers. Simply enter your postcode below and we'll show you the best broadband deals where you live.</p> -->
          <!-- <form action="method" id="home-checker" class="pc-checker">
            <select name="cat_id" id="category" v-model="catName" >
              <option value="">Choose Category</option>
              <template v-for="(category, i) in categorys">
                <option :key="i" :value="category.id" :data-value="category.id">{{category.title}}</option>
              </template>
            </select>
            <input
              type="text"
              name="input"
              placeholder="Search entire store here"
              required="required"
              v-model.trim="$v.search.$model"
              class="pc-checker__input"
              :class="{
                'is-invalid top-cat-field': $v.search.$error,
                'is-valid top-cat-field': !$v.search.$invalid,
                'top-cat-field':'top-cat-field'
              }"
            />
            <router-link :to="'/search?cat_id='+ catName +'&input='+ this.search">
              <input type="submit" class="pc-checker__button" value="Search" />
            </router-link> <br/>
          </form> -->
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <categorysPop />
      <div class="container">
        <div class="row Networks_phones text-center">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-12">
                  <networkCopm />
                </div>
                <!-- <div class="col-lg-12">
                  <h3 class="Networks_Brands mt-5 mt-md-0">Brands</h3>
                  <div class="row">
                    <div class="col-12 mt-5 Networks_Brands_img">
                        <brandsCopm />
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
      </div>
      <homeOffersCom />
      <!-- /////////////////////////////////////////////////////////// -->
      <!-- <div class="row">
        <div class="col-12">
          <div class="row content_block">
            <div
              class="col-12 col-lg-8 content_block_div_1 pt-4 pl-4 pl-lg-5 pb-0 pb-lg-4"
            >
              <h2>The best deals. The latest phones.</h2>
              <p class="mt-4">
                Mobile Phones&nbsp;makes it easy for you to check out all the
                latest smartphone releases,
                <span class="compare_deals">compare deals</span> and find the
                ideal tariff.
              </p>

              <p>
                Our quick&nbsp;search tools let you choose your preferred
                manufacturers, and specify exactly what you need from your phone
                plan. &nbsp;You can see
                <span class="compare_deals">great offers</span> from the UK’s
                leading networks, and our detailed phone reviews give you more
                information on specs, usability and&nbsp;design.
              </p>

              <p>
                Mobile Phones&nbsp;is a fair, impartial and informative
                comparison service, giving you everything you need to
                <span class="compare_deals">find&nbsp;the right deal</span>.
              </p>
            </div>
            <div class="col-12 col-lg-4 Filter_Search_results">
              <ul>
                <div class="row ">
                  <li class="col-12">
                    <div class="float-left row mt-4">
                      <i
                        class="col-2 col-lg-3 fas fa-filter fa-3x Filter_Search_results_i pl-3 pl-lg-4"
                      ></i>
                      <div class="col-9 pl-0">
                        <h3 class="mb-1">Filter Search</h3>
                        <p>
                          Filter search results to get exactly what you need
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="col-12">
                    <div class="float-left row mt-2">
                      <i
                        class="col-2 col-lg-3 fas fa-comment-alt fa-3x Filter_Search_results_i pl-3 pl-lg-4"
                      ></i>
                      <div class="col-9 pl-0">
                        <h3 class="mb-1">Phone Reviews</h3>
                        <p>
                          Read reviews of the latest smartphones and tablets
                        </p>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div> -->
      <!-- /////////////////////////////////////////////////////////// -->
      <!-- <div class="row latest_phones_leading">
        <div class="col-12">
          <div class="row ">
            <div class="col-12 col-lg-4 the_latest_phones">
              <ul class="">
                <div class="row">
                  <li class="col-12 ">
                    <div class="float-left row mt-4">
                      <i
                        class=" col-2 col-lg-3 fas fa-mobile-alt fa-3x the_latest_phones_i mt-3 pl-3 pl-lg-4"
                      ></i>
                      <div class="col-9 pl-0">
                        <h3 class="mb-1">Compare Phones</h3>
                        <p>
                          Compare the latest phones from leading manufacturers
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="col-12 ">
                    <div class="float-left row mt-2">
                      <i
                        class="col-2 col-lg-3 fas fa-save fa-3x the_latest_phones_i mt-3  pl-3 pl-lg-4"
                      ></i>
                      <div class="col-9 pl-0">
                        <h3 class="mb-1">Save Money</h3>
                        <p>
                          Find some of the best prices on major network tariffs
                        </p>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
            <div class=" col-12 col-lg-8 compare_with_Mobile mt-4 pl-4">
              <h2 class="">Why compare with Mobile Phones?</h2>
              <ul class="">
                <li class="mt-3">
                  <i class="fas fa-check compare_with_Mobile_i mr-2"></i>Compare
                  phones and tablets and get accurate, unbiased results
                </li>
                <li class="mt-2">
                  <i class="fas fa-check compare_with_Mobile_i mr-2"></i>Easy
                  filtering so you can see a range of ideal plans and tariffs
                </li>
                <li class="mt-2">
                  <i class="fas fa-check compare_with_Mobile_i mr-2"></i>Find
                  offers on pay monthly handsets and SIM Only
                </li>
                <li class="mt-2">
                  <i class="fas fa-check compare_with_Mobile_i mr-2"></i
                  >In-depth reviews of the latest smartphones and tablets
                </li>
                <li class="mt-3 mb-5">
                  <a href="" class=" compare_with_Mobile_a"
                    >Start searching now</a
                  >
                  to see all the latest deals and offers.
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      </div> -->
      
      <!-- /////////////////////////////////////////////////////////// -->
      
      <!-- <simCardCopm /> -->
      <!-- <newsCopm /> -->
    </div>
    <div class="bg_sky">
        <div class="container-fluid">
          <div class="row text-center justify-content-center mt-4 pt-4">
              <div class="title col-12 pt-3 pb-3">
                  <h2>Why compare with us?</h2>
              </div>
              <div class="para col-md-8 co-xs-12">
                  <p class="paragraph">
                      site-name.co.uk helps you quickly and easily compare broadband,TV,
                      <br/>landline and mobile packages from the UK's leading broadband, TV, phone and mobile providers.</p>
              </div>
          </div>
          <div class="row justify-content-center card-deck categories pt-5 pb-5">

              <div class="card col-md-3">
                  <img src="/assets/img/save-money.png" class="card-img-top" alt="...">
                  <div class="card-body">
                      <h5 class="card-title">Save money</h5>
                      <p class="card-text">You could save hundreds of pounds per year when you switch your broadband or TV provider with us today.</p>
                  </div>
              </div>
              <div class="card col-md-3">
                  <img src="/assets/img/trusted.png" class="card-img-top" alt="...">
                  <div class="card-body">
                      <h5 class="card-title">Trusted and rated</h5>
                      <p class="card-text">Our visitors have rated our service an average of 8.1 out of 10 according to our user reviews posted
                          on Trustpilot.</p>
                  </div>
              </div>
              <div class="card col-md-3">
                  <img src="/assets/img/secure.png" class="card-img-top" alt="Safe and secure">
                  <div class="card-body">
                      <h5 class="card-title">Safe and secure</h5>
                      <p class="card-text">Cable.co.uk is hosted on secure servers, meaning you can compare with confidence and peace of mind.</p>
                  </div>
              </div>
          </div>
      </div>
      </div>
      <newsCopm />
  </div>
</template>

<script>
import categorysPop from "@/views/pages/home/component/popular.vue";
import homeOffersCom from "@/views/pages/home/component/homeOffersCom.vue";
import networkCopm from "@/views/pages/pagesComponents/network.vue";
import brandsCopm from "@/views/pages/pagesComponents/brands.vue";
// import simCardCopm from "@/views/pages/home/component/simCard.vue";
import newsCopm from "@/views/pages/home/component/news.vue";
import PageLoader from "@/components/units/PageLoader.vue";
import { required, minLength } from "vuelidate/lib/validators"; 


export default {
  name: "Home",
  components: {
    categorysPop,
    homeOffersCom,
    networkCopm,
    brandsCopm,
    // popularCopm,
    // simCardCopm,
    newsCopm,
    PageLoader
  },
  data() {
    return {
      categorys: '',
      catName: ''
      
    };
  },
   validations: {
    search: {
      required,
      minLength: minLength(3)
    }
  },
  mounted (){
   window.scrollTo(0, 0)
    this.getData()
  } , 
  watch: {
    
  },
  methods: {
    getData() {
      this.loading = true;
      this.$urlmain
        .get("category-popular.json")
        .then(response => {
          this.categorys = response.data.data;
          response => (this.categorys = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style scope>
.main-slider .VueCarousel-dot-container {
  position: absolute !important;
  right: 0 !important;
  bottom: 10px !important;
  left: 0 !important;
  z-index: 15 !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  padding-left: 0 !important;
  margin-right: 15% !important;
  margin-left: 15% !important;
  list-style: none !important;
}

.main-slider .VueCarousel-dot-container button {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  position: relative !important;
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 1 auto !important;
  flex: 0 1 auto !important;
  margin-right: 3px !important;
  margin-left: 3px !important;
  text-indent: -999px !important;
  background-color: #fff !important;
  opacity: 0.5;
}
.main-slider .VueCarousel-dot--active {
  opacity: 1 !important;
}
.main-slider .VueCarousel-dot[data-v-438fd353]:focus {
  outline: none;
}
</style>
