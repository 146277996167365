<template>
  <div class="Compare_Phones">
    <ul class="conatiner">
      <div class="row">
        <li v-for="(popular, i) in popular" :key="popular.title + i" class="col-lg-3 col-md-4 col-6 Compare_Phones_2">
            <div class="row text-center justify-content-center">
              <img :src="publicPath + popular.img" width="100px" alt="">
              <div class="col-12">
                <!-- <h4>{{popular.title}}</h4> -->
                <!-- <div v-html="popular.desc"></div> -->
                <router-link :to="'/categories/' + popular.slug" tag="a" class="btn btn-outline-dark btn-block mt-3">
                  {{popular.title}}
                </router-link>
              </div>
            </div>
        </li>
        <li v-for="(popular, i) in popular2" :key="popular.title + i" class="col-lg-3 col-md-4 col-6 Compare_Phones_2">
            <div class="row text-center justify-content-center">
              <img :src=" publicPath + popular.img " width="100px" alt="">
              <div class="col-12">
                <!-- <h4>{{popular.title}}</h4> -->
                <!-- <div v-html="popular.desc"></div> -->
                <router-link :to="'/news?category=' + popular.slug" tag="a" class="btn btn-outline-dark btn-block mt-3">
                  {{popular.title}}
                </router-link>
              </div>
            </div>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  name: "popularCopm",
  data() {
    return {
      popular: [],
      popular2: [],
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/"
    };
  },
  mounted() {
    this.getData();
    this.getDataBlog();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
      this.getDataBlog();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$urlmain
        .get("category-popular.json")
        .then(response => {
          this.popular = response.data.data;
          response => (this.popular = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getDataBlog() {
      this.loading = true;
      this.$urlmain
        .get("blogCategory-popular.json")
        .then(response => {
          this.popular2 = response.data.data;
          response => (this.popular2 = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
